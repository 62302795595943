<template>
  <div class="card card-body shadow-sm">
    <app-basic-table
      ref="basic_table"
      :table-name="$t('users.user list')"
      :filters.sync="filters"
      :setting-columns="columns"
      :endpoint-list="ENDPOINT.GATEWAY_HISTORY_LIST($route.params.id)"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
    >
      <template v-slot:filters>
        <div class="row mt-3">
          <div class="col-xl-4 col-lg-6 col-sm-6">
            <div class="form-group row">
              <div class="col-xl-12 col-lg-12 col-sm-12  mt-0">
                <label class="align-self-center mb-0 mr-5">{{ filters.date_equal.label }}</label>
              </div>
              <div class="col-xl-12 col-lg-12 col-sm-12  mt-3">
                <app-date-range-picker
                  :name="filters.date_equal.name"
                  v-model="filters.date_equal.value"
                  timePicker24Hour="false"
                  format="YYYY-MM-DD"
                  @input="filters.date_equal.value = $event"
                />
              </div>
            </div>
          </div>
        </div>
      </template>


      <template v-slot:body-cell-_id="props">
        <td class="app-align-middle">
          <p class="app-table-p app-cell-tooltip mb-0" style="text-align: center;vertical-align: middle">
            {{props.row._id}}
          </p>
        </td>
      </template>

      <template v-slot:body-cell-facility_id="props">
        <td class="app-align-middle">
          <p :data-original-title="props.row.facility_name" style="color: #007bff;" @click="handlerFacilityNameClick(props.row)"  class="apartment_name app-table-p app-cell-tooltip mb-0">
            {{props.row.facility_name}}
          </p>
        </td>
      </template>

      <template v-slot:table-menu-right>
        <button @click="handleBtnBackClick()" class="btn btn-default">
          {{ $t("common.back") }}
        </button>
      </template>


    </app-basic-table>

    <!--    <create-component-->
    <!--      ref="modal_create"-->
    <!--      @onSuccess="handleCreateSuccess"-->
    <!--    ></create-component>-->

    <!--    <update-component-->
    <!--      @onSuccess="handleUpdateSuccess"-->
    <!--      ref="modal_update"-->
    <!--    ></update-component>-->

  </div>
</template>

<script>
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
  import {ENDPOINT} from "../../../constants/api";
  import {FACILITIES_CONSTANT} from "../../../constants/facilities";
  import {BEACONS_CONSTANT} from "../../../constants/beacons";

  export default {
    name: "FacilityList",
    components: {
      "app-basic-table": AppBasicTable,
      AppDateRangePicker,
    },
    data() {
      return {
        idCache: null,
        paramGetList: {},
        filters: {
          date_equal: {
            name: "date",
            condition: "equal",
            label: this.$t("beacons.date")
          },
        },
        columns: [
          {name: "id", label: this.$t("common.id"), sortable: true, textAlign: 'text-center'},
          {name: "mac_id", label: this.$t("gateway.mac_id")},
          {name: "gateway_name", label: this.$t("beacons.name"), sortable: true},
          {name: "fetch_date",  sortable: true,label: this.$t("gateway.history_fetched_at"),textAlign: 'text-center'},
          {name: "facility_id", label: this.$t("gateway.facility"), sortable: true, textAlign: 'text-center'},
          {name: "number_beacon", label: this.$t("gateway.number_beacon"),  sortable: true,textAlign: 'text-center'},
        ],
        meta: {
          apartments: [],
        },
        endPoint: ''
      };
    },
    watch: {},
    methods: {
      async handleBtnBackClick() {
        await this.$router.push({
          name: this.ROUTES.ADMIN.GATEWAY_LIST,
          query : {
            'filters.status_enabled.equal': 'all',
            'filters.apartment_id_equal.equal' : 'all',
            'filters.facility_id.equal' : 'all'
          }
        });
      },
      onResetAllSearch() {
        this.$router.push({name: this.ROUTES.ADMIN.GATEWAY_HISTORY_LIST , query : {
            'filters.date.equal' : moment().format('YYYY-MM-DD')
          }})
      },
      handlerFacilityNameClick(entry) {
        let routeFacility = this.$router.resolve({name: this.ROUTES.ADMIN.FACILITY_EDIT, params: {id: entry.facility_id}});
        window.open(routeFacility.href, '_blank');
      },
      onSearch() {
        // this.getTourSelectName();
      },

    },
    mounted: function () {
      // this.getParamsFilters({})
    },
  }
</script>

<style scoped>
  table tr td {
    padding: 16.6px .75rem !important;
  }
</style>
